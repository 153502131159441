import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"

import { QUERY_KEYS } from "../../constants"
import useOnboardContext from "../../hooks/useOnboardContext"
import focus from "../../images/icons/focus-icon@2x.png"
import kindness from "../../images/icons/kindness-icon@2x.png"
import knowledge from "../../images/icons/knowledge-icon@2x.png"
import morning from "../../images/icons/morning-routine-icon@2x.png"
import stamina from "../../images/icons/stamina-icon@2x.png"
import strength from "../../images/icons/strength-icon@2x.png"
import { loadQuestionnaires } from "../../networking/user"
import { getAllQuestions } from "../../util/questionnaires"

const getUserReasons = (parentData: any, parentQuestionnaires: any) => {
  if (!parentData?.parentQuestionnaireMapping) return
  if (Object.keys(parentData.parentQuestionnaireMapping).length === 0) return

  if (
    parentData?.parentQuestionnaireMapping &&
    Object.keys(parentData.parentQuestionnaireMapping).length > 0
  ) {
    const firstAnswer: number = parentData?.parentQuestionnaireMapping["1"][0]
    const secondAnswer: number = parentData?.parentQuestionnaireMapping["2"][0]

    const firstAnswerText = parentQuestionnaires[0].answers.find(
      (answer: any) => answer.id === firstAnswer
    )?.answer
    const secondAnswerText = parentQuestionnaires[1].answers.find(
      (answer: any) => answer.id === secondAnswer
    )?.answer

    const icon1 = userIcons[firstAnswer]
    const icon2 = userIcons[secondAnswer]
    return [
      { icon: icon1, text: firstAnswerText },
      { icon: icon2, text: secondAnswerText },
    ]
  }
}

const userIcons = {
  1: stamina,
  2: morning,
  3: focus,
  4: strength,
  5: kindness,
  6: knowledge,
  8: stamina,
  9: morning,
  10: focus,
  11: strength,
  12: kindness,
  13: knowledge,
} as any

const ParentSignupReasons = () => {
  const [userReasons, setUserReasons] = useState<any>(null)

  const { parentData } = useOnboardContext()

  const { data: questionsResponse, status } = useQuery({
    queryKey: [QUERY_KEYS.QUESTIONNAIRES],
    queryFn: loadQuestionnaires,
    select: getAllQuestions,
  })

  useEffect(() => {
    if (!questionsResponse?.parentQuestions) return
    const reasons = getUserReasons(
      parentData,
      questionsResponse.parentQuestions
    )
    setUserReasons(reasons)
    // eslint-disable-next-line
  }, [questionsResponse])

  if (!userReasons || status === "loading") return <div></div>

  const hideSecondaryGoal =
    userReasons[0].text === userReasons[1].text ||
    userReasons[1].text === "Something else"

  return (
    <div className="signup-reasons">
      <div className="signup-reasons-title">Primary Goal:</div>
      <div className="signup-reason">
        {<img src={userReasons[0].icon} alt="icon" />}
        {userReasons[0].text}
      </div>
      {!hideSecondaryGoal && (
        <>
          <div className="signup-reasons-title">Secondary Goal:</div>
          <div className="signup-reason">
            {<img src={userReasons[1].icon} alt="icon" />}
            {userReasons[1].text}
          </div>
        </>
      )}
    </div>
  )
}

export default ParentSignupReasons

import {
  UserRole,
  QuestStatus,
  QuestInstance,
  FamilyChildProfile,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { QuestInstanceStats } from "../types/quests"
import { createJoonAPIClient } from "../util/joon-api"
import { sortByRoutine } from "../util/util"

// TODO: Move all other quest-related networking functions here

export const getQuestInstanceStats = async (userId: number) => {
  const API = createJoonAPIClient()
  const result = await API.get<QuestInstanceStats>(
    "/api/quest-instances/stats/",
    {
      params: { user_id: userId },
    }
  )
  return result.data
}

export const completeQuest = async ({
  instanceId,
  completionDifficulty,
  completionNotes,
}: {
  instanceId: number
  completionDifficulty?: number
  completionNotes?: string
}) => {
  const API = createJoonAPIClient()
  return API.post(`/api/quest-instances/${instanceId}/complete/`, {
    role: UserRole.PARENT,
    completion_difficulty: completionDifficulty,
    completion_notes: completionNotes,
  })
}

export const completeQuests = async ({
  questInstanceIds,
}: {
  questInstanceIds: number[]
}) =>
  Promise.all(questInstanceIds.map((id) => completeQuest({ instanceId: id })))

export const skipQuests = async ({
  questInstanceIds,
}: {
  questInstanceIds: number[]
}) => {
  const API = createJoonAPIClient()
  const skipQuestPromises = questInstanceIds.map((id) =>
    API.post(`/api/quest-instances/${id}/skip/`, {
      role: UserRole.PARENT,
    })
  )

  return Promise.all(skipQuestPromises)
}

export const rejectQuests = async ({
  questInstanceIds,
}: {
  questInstanceIds: number[]
}) => {
  const API = createJoonAPIClient()
  const rejectQuestPromises = questInstanceIds.map((id) =>
    API.post(`/api/quest-instances/${id}/reject/`, {
      role: UserRole.PARENT,
    })
  )

  return Promise.all(rejectQuestPromises)
}

export const getAssignedQuests = async (
  selectedDate: string,
  userIds: number[]
): Promise<QuestInstance[]> => {
  const API = createJoonAPIClient()

  const childQuestPromises = userIds.map((id) =>
    API.get<{ results: QuestInstance[] }>(`api/quest-instances/assigned/`, {
      params: {
        date: selectedDate,
        user_id: id,
        role: UserRole.PARENT,
      },
    })
  )
  const responses = await Promise.all(childQuestPromises)
  return sortByRoutine(responses.flatMap((res) => res.data.results))
}

export async function addQuest(user_id: number, questInfo: any) {
  const API = createJoonAPIClient()
  return API.post(`/api/quest-series/`, {
    user_id,
    role: UserRole.PARENT,
    ...questInfo,
  })
}

export async function editQuest(
  seriesId: number,
  userId: number,
  questInfo: any
) {
  const API = createJoonAPIClient()
  return API.put(`/api/quest-series/${seriesId}/`, {
    user_id: userId,
    role: UserRole.PARENT,
    ...questInfo,
  })
}

export async function deleteQuest(questId: number) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.delete(`api/quest-series/${questId}/`, {
    params: { role: UserRole.PARENT },
  })
}

export const getQuestsToReview = async (children: FamilyChildProfile[]) => {
  const API = createJoonAPIClient()
  try {
    const childrenIds = children.map((child) => child.user.id).join(",")
    const response = await API.get<{ results: QuestInstance[] }>(
      "api/quest-instances/",
      {
        params: {
          role: UserRole.PARENT,
          status: QuestStatus.COMPLETED,
          user_ids: childrenIds,
        },
      }
    )
    return response.data.results
  } catch (error) {
    throw error
  }
}

export async function verifyQuest({
  instanceId,
  data,
}: {
  instanceId: number
  data: {
    verification_notes?: string
    redeemable_reward?: number
    completion_difficulty?: number
    new_difficulty?: string
  }
}) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post(`api/quest-instances/${instanceId}/verify/`, {
    role: UserRole.PARENT,
    ...data,
  })
}

export const verifyAllQuests = async ({
  questInstanceIds,
}: {
  questInstanceIds: number[]
}) => {
  const verifyAllPromises = questInstanceIds.map((instanceId) =>
    verifyQuest({ instanceId, data: {} })
  )
  return Promise.all(verifyAllPromises)
}

export async function rejectQuest({
  instanceId,
  data,
}: {
  instanceId: number
  data: {
    verification_notes?: string
    completion_difficulty?: number
  }
}) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post(`api/quest-instances/${instanceId}/reject/`, {
    role: UserRole.PARENT,
    ...data,
  })
}

export async function retryQuest({
  instanceId,
  data,
}: {
  instanceId: number
  data: {
    verification_notes?: string
    completion_difficulty?: number
  }
}) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post(
    `api/quest-instances/${instanceId}/request-retry/`,
    { role: UserRole.PARENT, ...data }
  )
}

export const retryQuests = async ({
  questInstanceIds,
}: {
  questInstanceIds: number[]
}) => {
  const retryQuestPromises = questInstanceIds.map((id) =>
    retryQuest({ instanceId: id, data: {} })
  )

  return Promise.all(retryQuestPromises)
}

export const undoVerifyQuest = async (instanceId: number) => {
  const API = createJoonAPIClient()

  return API.post(`api/quest-instances/${instanceId}/undo-review/`, {
    role: UserRole.PARENT,
  })
}

export const getQuestLog = async ({ seriesId }: { seriesId: number }) => {
  const API = createJoonAPIClient()
  return API.get(`api/quest-instances/`, {
    params: {
      role: UserRole.PARENT,
      series_id: seriesId,
      max_date: dayjs().format("YYYY-MM-DD"),
    },
  }).then((res) => res.data.results)
}

export const getParentTaskInstances = async ({
  userId,
  seriesId,
  minDate,
  maxDate,
  status,
}: {
  userId: number
  seriesId?: number
  minDate?: string
  maxDate?: string
  status?: string
}) => {
  const API = createJoonAPIClient()
  return API.get<{ results: QuestInstance[] }>("api/quest-instances/", {
    params: {
      user_id: userId,
      series_id: seriesId,
      min_date: minDate,
      max_date: maxDate,
      role: UserRole.PARENT,
      status,
    },
  }).then((res) => res.data.results)
}

export const reassignQuest = async ({
  seriesId,
  userId,
}: {
  seriesId: number
  userId: number
}) => {
  const API = createJoonAPIClient()
  return API.post(`api/quest-series/${seriesId}/reassign/`, {
    role: UserRole.PARENT,
    user_id: userId,
  })
}

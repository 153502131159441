import {
  JoonUIColor,
  Typography,
  PauseIcon,
  CoinsIcon,
  CaretRightIcon,
  IconProps,
  FlexBox,
  SPACING,
} from "@joonapp/web-shared"

import { useCoinTransactionsModalStore } from "./coinTransactions/useTransactionsModalStore"
import FamilyItemButton from "./FamilyItemButton"
import { useGetChildStartedModal } from "./GetChildStartedModal"
import { useManageGameplayModalStore } from "./manageGameplay/useManageGameplayModalStore"
import { getChildImage } from "../../util/util"

type Props = {
  user: any
}

const FamilyItem = ({ user }: Props) => {
  const image = getChildImage(user)
  const { onOpen: openGetChildStartedModal } = useGetChildStartedModal()
  const { onOpen: openManageGameplayModal } = useManageGameplayModalStore()
  const { onOpen: openCoinTransactionModal } = useCoinTransactionsModalStore()

  return (
    <FlexBox justify="flex-end">
      <FlexBox
        justify="space-between"
        direction="row"
        align="center"
        wrap={false}
        gap={SPACING.space0}
        style={{
          width: "100%",
          height: "74px",
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
          background: JoonUIColor.background.primaryNeutral,
          zIndex: "50",
        }}
      >
        <FlexBox
          direction="row"
          align="center"
          justify="flex-start"
          gap={SPACING.space1}
          wrap={false}
          style={{
            marginLeft: SPACING.space4,
            textWrap: "nowrap",
            height: SPACING.space12,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minWidth: SPACING.space12,
            }}
          >
            <img
              src={image}
              style={{
                width: "auto",
                height: SPACING.space12,
                borderRadius: "8px",
              }}
              alt="Parent Avatar"
            />
          </div>
          <FlexBox direction="column" style={{ marginLeft: SPACING.space2 }}>
            <Typography variant="bodyBold">{user.name}</Typography>
            {!user.active_doter?.type && (
              <Typography
                variant="bodyBold"
                color={JoonUIColor.semantic.warning}
              >
                <div>{user.name} isn't set up yet!</div>
              </Typography>
            )}
          </FlexBox>
        </FlexBox>

        <FlexBox direction="row" align="center" justify="flex-end" wrap={false}>
          {!user.active_doter?.type ? (
            <FamilyItemButton
              icon={
                <CaretRightIcon color={JoonUIColor.icon.accent} size={18} />
              }
              label="Get Started"
              onClick={() => openGetChildStartedModal()}
            />
          ) : (
            <>
              <FamilyItemButton
                icon={<CoinsIcon color={JoonUIColor.icon.accent} size={24} />}
                label="View Coins"
                onClick={() => openCoinTransactionModal(user)}
              />
              <FamilyItemButton
                icon={
                  <ControllerIcon color={JoonUIColor.icon.accent} size={24} />
                }
                label="Manage Game"
                onClick={() => openManageGameplayModal(user.id)}
              />
            </>
          )}
        </FlexBox>
      </FlexBox>
      {user.active_doter?.is_paused && <PauseTag />}
    </FlexBox>
  )
}

const PauseTag = () => {
  return (
    <FlexBox
      direction="row"
      align="center"
      gap={SPACING.space1}
      style={{
        width: "fit-content",
        padding: `${SPACING.space1} ${SPACING.space2}`,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderBottomLeftRadius: SPACING.space3,
        borderBottomRightRadius: SPACING.space3,
        boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
        marginRight: SPACING.space2,
        background: JoonUIColor.background.lightGray,
      }}
    >
      <PauseIcon size={18} color={JoonUIColor.semantic.warning} />
      <Typography variant="bodySmall" color={JoonUIColor.semantic.warning}>
        Game Paused
      </Typography>
    </FlexBox>
  )
}

export default FamilyItem

const ControllerIcon = ({
  size = 24,
  color = JoonUIColor.icon.neutral,
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 34 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4034 3.94434C5.01191 3.94434 0.637695 8.31855 0.637695 13.71C0.637695 19.1015 5.01191 23.4757 10.4034 23.4757H23.4243C28.8158 23.4757 33.19 19.1015 33.19 13.71C33.19 8.31855 28.8158 3.94434 23.4243 3.94434H10.4034ZM25.8657 9.23408C26.4053 9.23408 26.9228 9.44843 27.3043 9.82998C27.6859 10.2115 27.9002 10.729 27.9002 11.2686C27.9002 11.8082 27.6859 12.3257 27.3043 12.7072C26.9228 13.0888 26.4053 13.3031 25.8657 13.3031C25.3261 13.3031 24.8086 13.0888 24.4271 12.7072C24.0455 12.3257 23.8312 11.8082 23.8312 11.2686C23.8312 10.729 24.0455 10.2115 24.4271 9.82998C24.8086 9.44843 25.3261 9.23408 25.8657 9.23408ZM20.576 16.1514C20.576 15.6118 20.7903 15.0944 21.1719 14.7128C21.5534 14.3313 22.0709 14.1169 22.6105 14.1169C23.1501 14.1169 23.6676 14.3313 24.0491 14.7128C24.4306 15.0944 24.645 15.6118 24.645 16.1514C24.645 16.691 24.4306 17.2085 24.0491 17.5901C23.6676 17.9716 23.1501 18.186 22.6105 18.186C22.0709 18.186 21.5534 17.9716 21.1719 17.5901C20.7903 17.2085 20.576 16.691 20.576 16.1514ZM9.18267 10.8617C9.18267 10.1852 9.7269 9.64098 10.4034 9.64098C11.0799 9.64098 11.6241 10.1852 11.6241 10.8617V12.4893H13.2517C13.9282 12.4893 14.4724 13.0335 14.4724 13.71C14.4724 14.3865 13.9282 14.9307 13.2517 14.9307H11.6241V16.5583C11.6241 17.2348 11.0799 17.7791 10.4034 17.7791C9.7269 17.7791 9.18267 17.2348 9.18267 16.5583V14.9307H7.55505C6.87858 14.9307 6.33434 14.3865 6.33434 13.71C6.33434 13.0335 6.87858 12.4893 7.55505 12.4893H9.18267V10.8617Z"
      fill={color}
    />
  </svg>
)

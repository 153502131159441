import { QuestInstance, QuestType } from "@joonapp/web-shared"
import dayjs from "dayjs"

import useTaskReflectionModalStore from "./useTaskReflectionModalStore"
import Card from "../../../components/card/Card"
import { completeQuestStatuses } from "../../../constants"
import { useParentTaskInstancesQuery } from "../../../networking/queries"

type Props = {
  task: QuestInstance
}

const ParentTaskCard = ({ task }: Props) => {
  const { onOpen: openTaskReflection } = useTaskReflectionModalStore()

  const isCompleted = completeQuestStatuses.includes(task.status)

  const { data: taskInstances } = useParentTaskInstancesQuery({
    maxDate: dayjs().format("YYYY-MM-DD"),
    minDate: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
  })
  const completedSeriesInstances = taskInstances?.filter(
    (instance: QuestInstance) =>
      instance.series.id === task.series.id &&
      completeQuestStatuses.includes(instance.status)
  )
  const subtitle =
    task.series.type === QuestType.REPEATING
      ? `Completed ${completedSeriesInstances ? `${completedSeriesInstances.length}x` : "--"} in past 7 days`
      : "One-time task"

  return (
    <Card
      key={task.id}
      type={isCompleted ? "secondary" : "primary"}
      title={task.series.title}
      subtitle={subtitle}
      isChecked={isCompleted}
      onClick={(e) => {
        e.stopPropagation()
        openTaskReflection(task)
      }}
    />
  )
}

export default ParentTaskCard

import {
  FamilyParentProfile,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import ManageProfileModal from "./manageProfile/ManageProfileModal"
import { useManageProfileModalStore } from "./manageProfile/useManageProfileModalStore"
import Card from "../../components/card/Card"
import { useUserQuery } from "../../networking/queries"
import { getParentAvatarImage } from "../../util/util"

type Props = {
  user: FamilyParentProfile
}

const ParentFamilyItem = ({ user }: Props) => {
  const { onOpen } = useManageProfileModalStore()
  const { user: currentUser } = useUserQuery()
  const isCurrentUser = user?.user.id === currentUser?.id
  return (
    <>
      <Card
        title={
          <FlexBox
            direction="row"
            align="center"
            justify="flex-start"
            gap={SPACING.space1}
          >
            <img
              src={getParentAvatarImage(user?.user.avatar) || undefined}
              style={{
                width: SPACING.space6,
                height: "auto",
                borderRadius: SPACING.space2,
              }}
              alt="Parent Avatar"
            />
            <Typography
              variant="bodyBold"
              style={{ marginLeft: SPACING.space1 }}
            >
              {user?.nickname}
            </Typography>
            {isCurrentUser && <Typography variant="body">(you)</Typography>}
          </FlexBox>
        }
        onClick={(e) => {
          if (!isCurrentUser) return
          e.stopPropagation()
          onOpen()
        }}
        buttonComponent={
          isCurrentUser && (
            <Typography
              textAlign="center"
              variant="caption"
              color={JoonUIColor.border.accent}
              style={{
                width: "34%",
                fontSize: SPACING.space4,
                whiteSpace: "nowrap",
              }}
            >
              Manage Profile
            </Typography>
          )
        }
        style={{
          pointerEvents: isCurrentUser ? "auto" : "none",
          height: SPACING.space12,
        }}
      />
      {isCurrentUser && <ManageProfileModal user={user} />}
    </>
  )
}

export default ParentFamilyItem

import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import { useDisplayParentTaskSection } from "./useDisplayParentTasksSection"
import {
  useIncidentTemplatesQuery,
  useParentTaskQuery,
} from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { groupQuestsByTherapist } from "../../../util/util"

const ParentTasksSectionSmall = ({ onClose }: { onClose: () => void }) => {
  const { data: parentTasks } = useParentTaskQuery()
  const groupedParentTasks = groupQuestsByTherapist(parentTasks)
  const { data: incidentTemplates } = useIncidentTemplatesQuery()

  const { displayParentTasksSection } = useDisplayParentTaskSection()

  useEffect(() => {
    if (displayParentTasksSection) {
      trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_CATCHUP_END_PARENT_TASKS_REMINDER)
    }
  }, [displayParentTasksSection])

  if (!displayParentTasksSection) return <></>

  const firstTherapistProfile =
    groupedParentTasks?.[0]?.[0]?.series?.assigner_profile

  const numberOfTasks =
    groupedParentTasks.flat().length +
    (incidentTemplates && incidentTemplates.length > 0 ? 1 : 0)

  const onClickButton = () => {
    onClose()
    trackAnalyticEvent(ANALYTIC_EVENTS.CATCHUP_END_PARENT_TASKS_REMINDER_CLOSE)
  }

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      align="center"
      justify="center"
      style={{
        background: JoonUIColor.background.lightAccent,
        borderRadius: SPACING.space4,
        padding: SPACING.space4,
      }}
    >
      <FlexBox
        direction="column"
        justify="center"
        align="center"
        gap={SPACING.space1}
      >
        <TherapistIcon size={32} color={JoonUIColor.background.accent} />
        <Typography variant="bodyBold" textAlign="center">
          You have {numberOfTasks} remaining tasks from{" "}
          {groupedParentTasks?.length === 1
            ? firstTherapistProfile?.nickname
            : "your child's therapists"}
        </Typography>
      </FlexBox>
      <Button text="Check them out" onClick={onClickButton} />
    </FlexBox>
  )
}

export default ParentTasksSectionSmall

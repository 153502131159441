import { create } from "zustand"

interface TrainingStore {
  selectedTrainingId: number | null
  setSelectedTrainingId: (trainingId: number | null) => void

  isOpen: boolean
  onOpen: (id: number) => void
  onClose: () => void

  moduleIndex: number
  setModuleIndex: (index: number) => void
  incrementModuleIndex: () => void
  decrementModuleIndex: () => void
}

const useTrainingStore = create<TrainingStore>((set) => ({
  selectedTrainingId: null,
  setSelectedTrainingId: (trainingId) =>
    set({ selectedTrainingId: trainingId }),

  isOpen: false,
  onOpen: (selectedTrainingId) => set({ isOpen: true, selectedTrainingId }),
  onClose: () => set({ isOpen: false, selectedTrainingId: null }),

  moduleIndex: 0,
  setModuleIndex: (index) => set({ moduleIndex: index }),
  incrementModuleIndex: () =>
    set((state) => ({ moduleIndex: state.moduleIndex + 1 })),
  decrementModuleIndex: () =>
    set((state) => ({ moduleIndex: state.moduleIndex - 1 })),
}))

export default useTrainingStore

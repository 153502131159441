import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useMemo } from "react"

import Card from "../../components/card/Card"
import { completeQuestStatuses } from "../../constants"
import { useParentTaskQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { groupQuestsByTherapist } from "../../util/util"
import ParentTaskCard from "../me/parentTasks/ParentTaskCard"
import useParentTasksModalStore from "../me/parentTasks/useParentTasksModalStore"

const ParentTasksList = () => {
  const { data: parentTasks } = useParentTaskQuery()
  const { onOpen } = useParentTasksModalStore()
  const groupedParentTasks = groupQuestsByTherapist(parentTasks)

  const maxDisplayedTasks = 3

  const displayedTasks = useMemo(() => {
    return groupedParentTasks[0]
      ?.sort((task) => {
        if (completeQuestStatuses.includes(task.status)) return 1
        else return -1
      })
      ?.slice(0, maxDisplayedTasks)
  }, [groupedParentTasks])

  if (groupedParentTasks?.length === 1) {
    return (
      <FlexBox direction="column" gap={SPACING.space1}>
        {displayedTasks.map((task) => (
          <ParentTaskCard task={task} />
        ))}
      </FlexBox>
    )
  } else
    return (
      <FlexBox direction="column" gap={SPACING.space1}>
        {groupedParentTasks?.map((tasks, i) => {
          const incompleteTasks = tasks?.filter(
            (task) => !completeQuestStatuses.includes(task.status)
          )
          return (
            <Card
              title={
                <FlexBox
                  justify="space-between"
                  direction="row"
                  wrap={false}
                  align="center"
                >
                  <div>From {tasks[0].series.assigner_profile?.nickname}</div>
                  {incompleteTasks?.length > 0 && (
                    <FlexBox
                      align="center"
                      justify="flex-end"
                      gap={SPACING.space2}
                    >
                      <Typography variant="bodySmall">Tasks due:</Typography>
                      <FlexBox
                        justify="center"
                        align="center"
                        style={{
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          background: JoonUIColor.semantic.alert,
                        }}
                      >
                        <Typography
                          variant="caption"
                          color={JoonUIColor.text.inverted}
                          style={{ lineHeight: "20px" }}
                        >
                          {incompleteTasks.length}
                        </Typography>
                      </FlexBox>
                    </FlexBox>
                  )}
                </FlexBox>
              }
              onClick={() => {
                onOpen(tasks[0].series.assigner_profile?.user_id as number)
                trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TASKS_SHEET)
              }}
              key={i}
            />
          )
        })}
      </FlexBox>
    )
}

export default ParentTasksList

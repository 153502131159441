import {
  AuthCodeType,
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { useInviteCodeStore } from "./useInviteCodeStore"
import BackButton from "../../components/buttons/BackButton"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { createJoonAPIClient } from "../../util/joon-api"
import { useRouter } from "../../util/router"

function InviteCode() {
  const router = useRouter()
  const { setIsInvited, therapistCode, setTherapistCode } = useInviteCodeStore()
  const [error, setError] = useState<string | null>(null)
  const [isValidating, setIsValidating] = useState(false)

  const onSubmitTherapistCode = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.ONBOARDING_USING_THERAPIST_CODE)
    localStorage.setItem("therapist_code", therapistCode)
    router.push("/onboard")
  }

  const onClickSignUpManually = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.NOT_INVITED_BY_THERAPIST)
    setIsInvited(false)
    router.push("/onboard")
  }

  const validateCode = async () => {
    if (therapistCode.length !== 8) return

    try {
      const API = createJoonAPIClient()
      setIsValidating(true)
      const response = await API.get("api/auth-codes/validate/", {
        params: {
          code: therapistCode.toUpperCase(),
          type: AuthCodeType.ADMIT_PATIENT,
        },
      })
      if (!response.data.group.profiles) return setError("An error occurred")
      setError("")
    } catch (error) {
      setError("Invalid code")
    }
    setIsValidating(false)
  }

  useEffect(() => {
    if (therapistCode.length === 8) validateCode()
    else setError("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistCode])

  useEffect(() => {
    const code = localStorage.getItem("therapist_code")
    if (code) {
      setTherapistCode(code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper style={{ background: "#F0F1FB" }}>
      <div className="onboard-wrapper" style={{ width: "min(450px, 100%)" }}>
        <BackButton
          onBack={() => {
            setIsInvited(false)
          }}
        />
        <div
          className="onboard-section-centered"
          style={{ gap: "30px", minHeight: "70vh" }}
        >
          <img
            src="/images/logos/logo_white.svg"
            alt="Joon"
            style={{ width: "96px" }}
          />
          <FlexBox direction="column" align="center" gap={SPACING.space6}>
            <Typography variant="h1">Enter therapist code</Typography>
            <FlexBox direction="column" align="center" gap={SPACING.space2}>
              <TextInput
                name="therapist code"
                value={therapistCode}
                onChange={(e: any) => setTherapistCode(e.target.value)}
                maxLength={8}
                fullWidth
                icon="code"
                placeholder="ABCD12345"
              />
              {error && <div className="error-text mt-0">{error}</div>}
              <Button
                fullWidth
                onClick={onSubmitTherapistCode}
                text="Submit"
                disabled={therapistCode.length !== 8 || !!error || isValidating}
                style={{ marginTop: SPACING.space1 }}
              />
            </FlexBox>
            <hr
              style={{
                backgroundColor: JoonUIColor.border.accent,
                width: "50%",
                marginTop: error ? 0 : SPACING.space2,
                marginBottom: SPACING.space2,
              }}
            />
            <FlexBox direction="column" align="center" gap={SPACING.space2}>
              <Typography variant="body" color={JoonUIColor.text.disabled}>
                Not working?
              </Typography>
              <Button
                fullWidth
                buttonType="secondary"
                onClick={onClickSignUpManually}
                text="Sign up manually"
              />
            </FlexBox>
          </FlexBox>
        </div>
      </div>
    </PageWrapper>
  )
}

export default InviteCode

import { setDeviceId } from "@amplitude/analytics-browser"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { usePixelEvent } from "./usePixelEvent"
import { useFamilyQuery, useUserQuery } from "../networking/queries"
import {
  ANALYTIC_EVENTS,
  FacebookPixelEvent,
  TiktokPixelEvent,
  analyticsUserDidLogin,
  trackAnalyticEvent,
} from "../util/analytics"
import { useRouter } from "../util/router"
import { localStorageItems } from "../util/storage"

export const useAnalytics = () => {
  const location = useLocation()
  const { user, userStatus } = useUserQuery()
  const { data: family, status: familyStatus } = useFamilyQuery()
  const router = useRouter()
  const { trackFBPixelEvent, trackTikTokPixelEvent } = usePixelEvent()
  const deviceId = router?.query?.deviceId

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // @ts-ignore
      const navType = event.currentTarget?.performance?.navigation?.type
      if (navType === 0) trackAnalyticEvent(ANALYTIC_EVENTS.PAGE_CLOSE)
      else if (navType === 1) trackAnalyticEvent(ANALYTIC_EVENTS.PAGE_REFRESH)
      else if (navType === 2)
        trackAnalyticEvent(ANALYTIC_EVENTS.PAGE_BACK_FORWARD)
    }

    // Add the event listener
    window.addEventListener("beforeunload", handleBeforeUnload)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (!deviceId) return
    setDeviceId(deviceId)
    // masking the url if user is not logged in
    // because otherwise it will already redirect
    if (!user)
      window.history.replaceState(
        null,
        "Joon - Welcome",
        router.location.pathname
      )
  }, [router, deviceId, user])

  // Set transaction_id if exists on initial, to later send as tune_id when creating user
  useEffect(() => {
    const transaction_id = router?.query?.transaction_id
    if (!transaction_id) return

    localStorage.setItem(localStorageItems.tuneId, transaction_id)
    // eslint-disable-next-line
  }, [router])

  useEffect(() => {
    const isProduction = import.meta.env.MODE === "production"
    if (!isProduction) return
    trackAnalyticEvent("page:shown", { page: location.pathname })
  }, [location.pathname])

  useEffect(() => {
    if (userStatus === "success" && familyStatus === "success") {
      if (!user || !family) return
      const parentId = user.legacy_parent_id ?? user.id
      const familyId = family.legacy_family_id ?? family.id

      analyticsUserDidLogin(parentId, familyId, user.email)
    }
  }, [familyStatus, userStatus, user, family])

  // track complete registration event after signup
  useEffect(() => {
    const needsToFireRegistrationEvent = !!localStorage.getItem(
      localStorageItems.needsToFireRegistrationEvent
    )
    if (!needsToFireRegistrationEvent || !user) return
    trackFBPixelEvent(FacebookPixelEvent.COMPLETE_REGISTRATION)
    trackTikTokPixelEvent(TiktokPixelEvent.COMPLETE_REGISTRATION)
    localStorage.removeItem(localStorageItems.needsToFireRegistrationEvent)
  }, [user, trackFBPixelEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const queryParams = router.query
    if (queryParams.email_campaign) {
      trackAnalyticEvent(ANALYTIC_EVENTS.EMAIL_CAMPAIGN_CLICKED, queryParams)
    }
  }, [router.query])
}

import { IncidentTemplate } from "../types"
import { createJoonAPIClient } from "../util/joon-api"

export const getIncidents = async () => {
  const API = createJoonAPIClient()

  const response = await API.get<{ templates: IncidentTemplate[] }>(
    "api/incident-logs/form-templates/"
  )

  return response.data.templates
}

export const createIncident = async ({
  userId,
  targetBehaviorId,
  freeformTargetBehavior,
  strategyId,
  freeformPreventiveStrategy,
  incidentDateTime,
  duration,
  intensity,
  responseIntensity,
}: {
  userId: number
  targetBehaviorId?: number
  freeformTargetBehavior?: string
  strategyId?: number
  freeformPreventiveStrategy?: string
  incidentDateTime?: string
  duration?: string
  intensity?: number
  responseIntensity?: number
}) => {
  const API = createJoonAPIClient()

  const response = await API.post("api/incident-logs/", {
    user_id: userId,
    target_behavior_id: targetBehaviorId,
    freeform_target_behavior: freeformTargetBehavior,
    preventive_strategy_id: strategyId,
    freeform_preventive_strategy: freeformPreventiveStrategy,
    incident_datetime: incidentDateTime,
    duration,
    intensity,
    response_intensity: responseIntensity,
  })

  return response.data
}

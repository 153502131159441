import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"

import ParentTasksList from "./ParentTasksList"
import { useParentTaskQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { groupQuestsByTherapist } from "../../util/util"
import ReportIncidentButton from "../me/incidents/ReportIncidentButton"
import useParentTasksModalStore from "../me/parentTasks/useParentTasksModalStore"

const AcademyPageHeader = () => {
  const { data: parentTasks } = useParentTaskQuery()
  const { onOpen } = useParentTasksModalStore()
  const groupedParentTasks = groupQuestsByTherapist(parentTasks)

  const firstTherapistProfile =
    groupedParentTasks?.[0]?.[0]?.series?.assigner_profile

  const maxDisplayedTasks = 3
  const showViewAllButton =
    groupedParentTasks?.length > 1 ||
    groupedParentTasks?.[0]?.length > maxDisplayedTasks

  const onClickViewAll = () => {
    if (!showViewAllButton) return
    onOpen(firstTherapistProfile?.user_id as number)
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TASKS_SHEET)
  }

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      wrap={false}
      style={{
        background: JoonColorExpanded.purple600,
        width: "min(600px, 100%)",
        padding: SPACING.space4,
        borderRadius: `0 0 ${SPACING.space4} ${SPACING.space4}`,
      }}
    >
      <Typography variant="h2" color={JoonUIColor.text.inverted}>
        Joon Academy
      </Typography>
      <FlexBox direction="row" wrap={false} gap={SPACING.space4}>
        <FlexBox wrap={false} align="center" gap={SPACING.space1}>
          <TherapistIcon size={24} color={JoonColorExpanded.purple600} />
          <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
            Daily to-dos
          </Typography>
        </FlexBox>
        {showViewAllButton && (
          <button onClick={onClickViewAll}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.inverted}
              style={{ whiteSpace: "nowrap" }}
            >
              View all
            </Typography>
          </button>
        )}
      </FlexBox>
      <ReportIncidentButton />
      <ParentTasksList />
    </FlexBox>
  )
}

export default AcademyPageHeader

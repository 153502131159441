import {
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import ParentTaskCard from "./ParentTaskCard"
import useParentTasksModalStore from "./useParentTasksModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { GRADIENTS, completeQuestStatuses } from "../../../constants"
import { useParentTaskQuery } from "../../../networking/queries"

const ParentTasksModal = () => {
  const { isOpen, onClose, selectedTherapistId } = useParentTasksModalStore()
  const { data: parentTasks } = useParentTaskQuery()
  const tasks = parentTasks
    ?.filter(
      (task) => task.series.assigner_profile?.user_id === selectedTherapistId
    )
    .sort((task) => {
      if (completeQuestStatuses.includes(task.status)) return 1
      else return -1
    })

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={true}
      size="default"
      backgroundType="overlay"
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onClose}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        wrap={false}
        style={{
          padding: SPACING.space6,
          background: GRADIENTS.gradient100,
          height: "100%",
          overflowY: "auto",
        }}
      >
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="h3">
            Tasks from {tasks?.[0]?.series?.assigner_profile?.nickname}
          </Typography>
          <Typography variant="bodySmall">
            Your child's therapist assigned the following tasks for you to do in
            between sessions.
          </Typography>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2}>
          {tasks?.map((task) => <ParentTaskCard task={task} />)}
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}

export default ParentTasksModal

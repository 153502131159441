import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import React from "react"

type Props = {
  icon: React.ReactNode
  label: string
  onClick: () => void
}

const FamilyItemButton = ({ icon, label, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      style={{
        width: "fit-content",
        height: "74px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: SPACING.space2,
        flexDirection: "column",
        color: JoonUIColor.text.primaryAccent,
        background: JoonUIColor.background.lightGray,
        padding: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        cursor: "pointer",
      }}
    >
      <FlexBox direction="column" align="center" gap={SPACING.space1}>
        <div
          style={{
            height: SPACING.space6,
            width: SPACING.space6,
          }}
        >
          {icon}
        </div>
        <Typography variant="bodyXSmall" color={JoonUIColor.text.primaryAccent}>
          {label}
        </Typography>
      </FlexBox>
    </button>
  )
}

export default FamilyItemButton

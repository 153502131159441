import {
  ChevronLeftIcon,
  JoonUIColor,
  SPACING,
  TextButton,
} from "@joonapp/web-shared"

interface Props {
  onBack: () => void
}

function BackButton({ onBack, ...props }: Props) {
  return (
    <TextButton
      onClick={onBack}
      style={{
        background: JoonUIColor.background.accent,
        width: "50px",
        borderRadius: "30px",
        padding: `${SPACING.space1} 0`,
      }}
      {...props}
    >
      <ChevronLeftIcon color={JoonUIColor.icon.inverted} size={24} />
    </TextButton>
  )
}

export default BackButton

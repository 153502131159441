import {
  Button,
  FlexBox,
  Modal,
  ModalHeader,
  QuestInstance,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { useReviewQuestModal } from "./ReviewQuestModal"
import { QUERY_KEYS } from "../../constants"
import { rejectQuest, retryQuest } from "../../networking/quests"

interface RejectQuestModalStore {
  isOpen: boolean
  onOpen: (quest?: QuestInstance) => void
  onClose: () => void

  quest: QuestInstance | null
}

export const useRejectQuestModal = create<RejectQuestModalStore>((set) => ({
  isOpen: false,
  onOpen: (quest?: QuestInstance) => set({ quest, isOpen: true }),
  onClose: () => set({ isOpen: false }),

  quest: null,
}))

const RejectQuestModal = () => {
  const [isLoadingReject, setIsLoadingReject] = useState(false)
  const [isLoadingRetry, setIsLoadingRetry] = useState(false)
  const { isOpen, onClose: closeRejectModal, quest } = useRejectQuestModal()
  const { onClose: closeReviewModal, notes, difficulty } = useReviewQuestModal()
  const queryClient = useQueryClient()

  const clickRejectButton = async (type: "reject" | "retry") => {
    if (!quest) return
    const body = {
      verification_notes: notes,
      completion_difficulty: difficulty,
    }
    if (type === "reject") {
      try {
        setIsLoadingReject(true)
        await rejectQuest({ instanceId: quest.id, data: body })
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_REVIEW])
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
        toast.success("Quest rejected!")
      } catch (error) {
        toast.error("Failed to reject quest")
      } finally {
        setIsLoadingReject(false)
      }
    } else if (type === "retry") {
      try {
        setIsLoadingRetry(true)
        await retryQuest({ instanceId: quest.id, data: body })
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_REVIEW])
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
        toast.success("Quest marked for retry!")
      } catch (error) {
        toast.error("Failed to retry quest")
      } finally {
        setIsLoadingRetry(false)
      }
    }
    closeReviewModal()
    closeRejectModal()
  }

  if (!quest) return <></>

  return (
    <Modal isOpen={isOpen} onClose={closeRejectModal} animate>
      <ModalHeader title="Are you sure?" onClose={closeRejectModal} />
      <FlexBox
        direction="column"
        gap="24px"
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space2,
          width: "min(400px, 95vw)",
        }}
      >
        <Typography variant="body">
          You can also have them retry the Quest if you think your child can do
          better!
        </Typography>
        <FlexBox
          direction="row"
          justify="space-between"
          gap={SPACING.space6}
          wrap={false}
        >
          <Button
            text="Retry"
            isLoading={isLoadingRetry}
            disabled={isLoadingReject || isLoadingRetry}
            onClick={() => clickRejectButton("retry")}
          />
          <Button
            text="Reject"
            isLoading={isLoadingReject}
            disabled={isLoadingReject || isLoadingRetry}
            buttonType="redPrimary"
            onClick={() => clickRejectButton("reject")}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default RejectQuestModal

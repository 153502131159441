import {
  Button,
  Checkbox,
  FlexBox,
  InfoIcon,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { useViewQuestModalStore } from "./useViewQuestModalStore"
import { QUERY_KEYS } from "../../constants"
import { useFamilyQuery } from "../../networking/queries"
import { reassignQuest } from "../../networking/quests"
import { FamilyQuerySelect } from "../../types"

interface ReassignQuestModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useReassignQuestModalStore = create<ReassignQuestModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

const ReassignQuest = () => {
  const { isOpen, onClose } = useReassignQuestModalStore()
  const { questInstance } = useViewQuestModalStore()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const childrenToReassign = children
    ? children.filter((child) => child.user.id !== questInstance?.user_id)
    : []

  const [selectedChildId, setSelectedChildId] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()

  const onClickReassign = async () => {
    if (!selectedChildId || !questInstance || !children) return

    setIsLoading(true)
    try {
      await reassignQuest({
        userId: selectedChildId,
        seriesId: questInstance?.series.id,
      })
      // optimistic update. quest board query invaldiation won't actually update the quest being viewed
      // because a copy of the quest instance is being stored in state rather than the id itself.
      // may want to refactor to store the id instead of the whole quest object
      questInstance.user_id = selectedChildId
      toast.success(
        `Quest reassigned to ${children.find((child) => child.user.id === selectedChildId)?.user.name}`
      )
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      onClose()
    } catch (error) {
      toast.error("Error reassigning quest")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader
        onClose={onClose}
        title="Reassign this Quest"
        style={{ borderBottom: `1px solid ${JoonUIColor.border.default}` }}
      />
      <FlexBox
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
          width: "min(400px, 95vw)",
        }}
        direction="column"
        gap={SPACING.space2}
      >
        <Typography variant="body">
          Choose who this should be assigned to:
        </Typography>

        <FlexBox direction="row" gap={SPACING.space2} wrap={true}>
          {childrenToReassign.map((child) => (
            <Checkbox
              name={child.user.name}
              label={child.user.name}
              selected={selectedChildId === child.user.id}
              onChange={() => setSelectedChildId(child.user.id)}
            />
          ))}
        </FlexBox>
        <FlexBox
          direction="row"
          wrap={false}
          gap={SPACING.space2}
          style={{
            border: `1px solid ${JoonUIColor.semantic.destructive}`,
            borderRadius: SPACING.space2,
            background: JoonColorExpanded.red100,
            padding: SPACING.space2,
            marginBottom: SPACING.space4,
            marginTop: SPACING.space2,
          }}
        >
          <div style={{ minWidth: "20px", paddingTop: SPACING.space1 }}>
            <InfoIcon color={JoonUIColor.semantic.destructive} size={20} />
          </div>
          <Typography
            variant="caption"
            color={JoonUIColor.semantic.destructive}
          >
            Changing the Quest assignment will remove historical data and
            completion history for the previous owner of this task.
          </Typography>
        </FlexBox>
        <FlexBox
          direction="row"
          gap={SPACING.space4}
          align="center"
          wrap={false}
        >
          <button
            onClick={onClose}
            style={{ padding: `${SPACING.space1} ${SPACING.space3}` }}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </button>
          <Button
            text="Reassign Quest"
            onClick={onClickReassign}
            isLoading={isLoading}
            fullWidth
            disabled={!selectedChildId}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default ReassignQuest

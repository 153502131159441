import { useEffect } from "react"

import PageLoader from "../components/loading/PageLoader"
import CreatingPlan from "../components/onboarding/CreatingPlan"
import OnboardAddChildOrJoinFamily from "../components/onboarding/OnboardAddChildOrJoinFamily"
import OnboardAddChildrenSection from "../components/onboarding/OnboardAddChildrenSection"
import OnboardCoparentCodeSection from "../components/onboarding/OnboardCoparentCodeSection"
import OnboardParentAvatarSection from "../components/onboarding/OnboardParentAvatarSection"
import OnboardParentNameSection from "../components/onboarding/OnboardParentNameSection"
import QuestionnaireSection from "../components/onboarding/questionnaires/QuestionnaireSection"
import SignupSection from "../components/onboarding/SignupSection"
import PageWrapper from "../components/pageWrapper/PageWrapper"
import useDocumentTitle from "../hooks/useDocumentTitle"
import useOnboardContext, {
  OnboardingStep,
  useAccountCreation,
} from "../hooks/useOnboardContext"
import {
  useFamilyQuery,
  useQuestionnairesQuery,
  useUserQuery,
} from "../networking/queries"
import { QuestionAnswerMappingInterface } from "../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../util/analytics"
import { useRouter } from "../util/router"
import { sessionManager } from "../util/storage"

function OnboardAccountPage() {
  useDocumentTitle("Joon - Create Account")
  const router = useRouter()
  const { user } = useUserQuery()

  const {
    setParentData,
    currentStep,
    creatingPlan,
    nextStep,
    previousStep,
    parentQuestionnaireStep,
    setParentQuestionnaireStep,
  } = useOnboardContext()
  const { uploadOnboardingSecondaryData } = useAccountCreation()

  const { data: questionsResponse } = useQuestionnairesQuery()
  const { data: family } = useFamilyQuery()

  useEffect(() => {
    if (!sessionManager.getRefreshToken()) return
    if (user || family) return router.push("/quests")

    trackAnalyticEvent(ANALYTIC_EVENTS.START_ONBOARD, {
      utm_source: router.query.utm_source,
      utm_campaign: router.query.utm_campaign,
      ad_set_name: router.query.ad_set_name,
      ad_name: router.query.ad_name,
    })
    // eslint-disable-next-line
  }, [user])

  const onConfirmParentQuestionnaire = (parentQuestionnaireMapping: {
    questionAnswerMapping: QuestionAnswerMappingInterface
  }) => {
    setParentData(
      "parentQuestionnaireMapping",
      parentQuestionnaireMapping.questionAnswerMapping
    )
    const lastQuestionIndex = questionsResponse
      ? questionsResponse.parentQuestions.length - 1
      : 0
    setParentQuestionnaireStep(lastQuestionIndex)
    nextStep()
  }

  if (currentStep === OnboardingStep.CreateAccountWithCredentials) {
    uploadOnboardingSecondaryData()
  }

  return (
    <PageWrapper style={{ background: "#F0F1FB" }}>
      <div className="onboard-wrapper">
        {creatingPlan ? (
          <CreatingPlan />
        ) : currentStep === OnboardingStep.ParentNameNickname ? (
          <OnboardParentNameSection />
        ) : currentStep === OnboardingStep.ParentAvatar ? (
          <OnboardParentAvatarSection />
        ) : currentStep === OnboardingStep.ParentingQuestionnaire ? (
          <QuestionnaireSection
            questions={questionsResponse?.parentQuestions || []}
            onConfirm={onConfirmParentQuestionnaire}
            previousStep={previousStep}
            lastSeenStep={parentQuestionnaireStep}
          />
        ) : currentStep === OnboardingStep.AddChildOrJoinFamily ? (
          <OnboardAddChildOrJoinFamily />
        ) : currentStep === OnboardingStep.CoparentCode ? (
          <OnboardCoparentCodeSection />
        ) : currentStep === OnboardingStep.AddChildren ? (
          <OnboardAddChildrenSection />
        ) : currentStep === OnboardingStep.EmailPassword ? (
          <SignupSection />
        ) : (
          <PageLoader />
        )}
      </div>
    </PageWrapper>
  )
}

export default OnboardAccountPage

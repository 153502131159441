import { FamilyChildProfile, UserRole } from "@joonapp/web-shared"

import { trackAnalyticEvent } from "./analytics"
import { submitQuestionnaire } from "../networking/authentication"
import { ParentDataInterface, QuestionnairesInterface } from "../types"

export const submitParentQuestionnaires = (
  onboardingQuestionnaires: QuestionnairesInterface[],
  parentData: any,
  parentId: number
) => {
  onboardingQuestionnaires
    .filter((questionnaire: any) => questionnaire.type === "parent")
    .forEach((questionnaire: any) => {
      const answers = Object.keys(parentData.parentQuestionnaireMapping)
        .filter((questionId) =>
          questionnaire.questions.some(
            (question: any) => `${question.id}` === questionId
          )
        )
        .map((questionId) => {
          return {
            question_id: Number(questionId),
            answer_ids: parentData.parentQuestionnaireMapping[questionId],
          }
        })
      submitQuestionnaire(questionnaire.id, parentId, answers)
    })
}

export const submitChildQuestionnaires = (
  onboardingQuestionnaires: QuestionnairesInterface[],
  parentData: ParentDataInterface,
  children: FamilyChildProfile[]
) => {
  const childrenQuestionnaires = onboardingQuestionnaires.filter(
    (questionnaire) => questionnaire.type === UserRole.CHILD
  )
  children.forEach((child) => {
    const childQuestionnaireMapping = parentData.childrenData.find(
      (childData) => childData.first_name === child.user.name
    )?.questionAnswerMapping
    if (!childQuestionnaireMapping)
      return trackAnalyticEvent("childQuestionnaireMapping not found")

    childrenQuestionnaires.forEach((questionnaire) => {
      const answers = Object.keys(childQuestionnaireMapping)
        .filter((questionId) =>
          questionnaire.questions.some(
            (question) => `${question.id}` === questionId
          )
        )
        .map((questionId) => {
          const question_id = Number(questionId)
          return {
            question_id: question_id,
            answer_ids: childQuestionnaireMapping[question_id],
          }
        })
      submitQuestionnaire(questionnaire.id, child.user.id, answers)
    })
  })
}

export const getQIDMapping = (questionnaire: any) => {
  return {
    id: questionnaire.id,
    questions: questionnaire.questions.map((q: any) => q.id),
  }
}

export const getChildQuestions = (questionnaires: any) => {
  const childQuestionnaires = questionnaires.filter(
    (q: any) => q.type === "child"
  )
  const allQuestions = childQuestionnaires.map((q: any) => q.questions).flat()
  const questionIdMap = childQuestionnaires.map(getQIDMapping)
  return { allQuestions, questionIdMap }
}
export const getParentQuestions = (questionnaires: any) => {
  const childQuestionnaires = questionnaires.filter(
    (q: any) => q.type === "parent"
  )
  const allQuestions = childQuestionnaires.map((q: any) => q.questions).flat()
  const questionIdMap = childQuestionnaires.map(getQIDMapping)
  return { allQuestions, questionIdMap }
}

export const getAllQuestions = (
  onboardingQuestionnaires: QuestionnairesInterface[]
) => {
  const parentQuestions = onboardingQuestionnaires
    .filter((questionnaire) => questionnaire.type === "parent")
    .map((questionnaire) => questionnaire.questions)
    .flat()
  const childQuestions = onboardingQuestionnaires
    .filter((questionnaire) => questionnaire.type === "child")
    .sort((q1, q2) => q1.id - q2.id)
    .map((questionnaire) => questionnaire.questions)
    .flat()
  return { parentQuestions, childQuestions, onboardingQuestionnaires }
}

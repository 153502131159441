import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"

import ParentTaskCard from "./ParentTaskCard"
import { useDisplayParentTaskSection } from "./useDisplayParentTasksSection"
import useParentTasksModalStore from "./useParentTasksModalStore"
import Card from "../../../components/card/Card"
import { completeQuestStatuses } from "../../../constants"
import { useParentTaskQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { groupQuestsByTherapist } from "../../../util/util"
import ReportIncidentButton from "../incidents/ReportIncidentButton"

const ParentTasksSection = () => {
  const { data: parentTasks } = useParentTaskQuery()
  const { onOpen } = useParentTasksModalStore()
  const { displayParentTasksSection } = useDisplayParentTaskSection()
  const groupedParentTasks = groupQuestsByTherapist(parentTasks)

  if (!displayParentTasksSection) return <></>

  const firstTherapistProfile =
    groupedParentTasks?.[0]?.[0]?.series?.assigner_profile

  const maxDisplayedTasks = 3
  const showViewAllButton =
    groupedParentTasks?.length > 1 ||
    groupedParentTasks?.[0]?.length > maxDisplayedTasks

  return (
    <button
      style={{
        padding: 0,
        marginTop: SPACING.space1,
        cursor: showViewAllButton ? "pointer" : "default",
      }}
      onClick={() => {
        if (!showViewAllButton) return
        onOpen(firstTherapistProfile?.user_id as number)
        trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TASKS_SHEET)
      }}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        align="center"
        style={{
          background:
            "linear-gradient(108deg, rgba(255, 255, 255, 0.60) -6.4%, rgba(255, 255, 255, 0.80) 20.05%, rgba(255, 255, 255, 0.70) 69.45%, rgba(255, 255, 255, 0.60) 111.48%)",
          borderRadius: SPACING.space4,
          padding: SPACING.space4,
        }}
      >
        <FlexBox
          direction="column"
          justify="center"
          align="center"
          gap={SPACING.space1}
        >
          <TherapistIcon size={32} color={JoonUIColor.background.accent} />
          <Typography variant="bodyBold">
            Your tasks from{" "}
            {groupedParentTasks?.length === 1
              ? firstTherapistProfile?.nickname
              : "your child's therapists"}
          </Typography>
        </FlexBox>
        <ReportIncidentButton />
        {groupedParentTasks?.length === 1 ? (
          <FlexBox direction="column" gap={SPACING.space1}>
            {groupedParentTasks[0]
              ?.sort((task) => {
                if (completeQuestStatuses.includes(task.status)) return 1
                else return -1
              })
              ?.slice(0, maxDisplayedTasks)
              .map((task) => <ParentTaskCard task={task} />)}
            {showViewAllButton && (
              <Typography
                variant="caption"
                color={JoonUIColor.text.primaryAccent}
                style={{ marginTop: SPACING.space1 }}
              >
                Show all
              </Typography>
            )}
          </FlexBox>
        ) : (
          <FlexBox direction="column" gap={SPACING.space1}>
            {groupedParentTasks?.map((tasks, i) => {
              const incompleteTasks = tasks?.filter(
                (task) => !completeQuestStatuses.includes(task.status)
              )
              return (
                <Card
                  title={
                    <FlexBox
                      justify="space-between"
                      direction="row"
                      wrap={false}
                      align="center"
                    >
                      <div>
                        From {tasks[0].series.assigner_profile?.nickname}
                      </div>
                      {incompleteTasks?.length > 0 && (
                        <FlexBox
                          align="center"
                          justify="flex-end"
                          gap={SPACING.space2}
                        >
                          <Typography variant="bodySmall">
                            Tasks due:
                          </Typography>
                          <FlexBox
                            justify="center"
                            align="center"
                            style={{
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              background: JoonUIColor.semantic.alert,
                            }}
                          >
                            <Typography
                              variant="caption"
                              color={JoonUIColor.text.inverted}
                              style={{ lineHeight: "20px" }}
                            >
                              {incompleteTasks.length}
                            </Typography>
                          </FlexBox>
                        </FlexBox>
                      )}
                    </FlexBox>
                  }
                  onClick={() => {
                    onOpen(tasks[0].series.assigner_profile?.user_id as number)
                    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TASKS_SHEET)
                  }}
                  key={i}
                />
              )
            })}
          </FlexBox>
        )}
      </FlexBox>
    </button>
  )
}

export default ParentTasksSection

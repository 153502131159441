import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"

import App from "./App.tsx"
import "./styles/index.scss"

import.meta.env.PROD &&
  Sentry.init({
    dsn: "https://9a1e012942864d58a8788f4faa4cc0a6@o4505077617524736.ingest.us.sentry.io/4505200790142976",
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysOnErrorSampleRate: 1.0,
  })

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

export interface LocalStorageInterface {
  // Auth tokens
  getRefreshToken: () => string | null
  hasRefreshToken: () => boolean
  getIDToken: () => string | null
  storeTokens: (refreshToken: string | null, idToken: string | null) => void
  clearAuthTokens: () => void

  // Flags
  setLocalStorageFlag: (flag: string) => void
  setNewUserLocalStorageFlags: () => void

  needsToSeeVerifyFreemiumPopup: () => boolean
  clearNeedsToSeeVerifyFreemiumPopup: () => void
}

// constant object for storage items
export const localStorageItems = {
  refreshToken: "REFRESH",
  idToken: "TOKEN",
  analyticsFamilyId: "familyId",
  analyticsUserId: "userId",

  // Onboarding
  onboardStep1: "ONBOARD_STEP_1",
  onboardStep2: "ONBOARD_STEP_2",
  onboardStep3: "ONBOARD_STEP_3",
  showDownloadAppBanner: "SHOW_DOWNLOAD_APP_BANNER",
  needsToSeePaywall: "needsToSeePaywall",
  needsToSeeVerifyFreemiumPopup: "VERIFY_FREEMIUM_POPUP",
  needsToFireRegistrationEvent: "FIRE_REGISTRATION_EVENT",
  needsToShowChildOnboardAfterBanner: "SHOW_CHILD_ONBOARD_AFTER_BANNER",

  showABAInviteSection: "showABAInviteSection",

  redirectLink: "redirectLink",
  isCoparent: "isCoparent",
  tuneId: "tuneId",
} as const

export const sessionManager: LocalStorageInterface = {
  // TOKENS
  getRefreshToken: () => localStorage.getItem(localStorageItems.refreshToken),
  hasRefreshToken: () => !!localStorage.getItem(localStorageItems.refreshToken),
  getIDToken: () => localStorage.getItem(localStorageItems.idToken),
  storeTokens: (refreshToken: string | null, idToken: string | null) => {
    refreshToken &&
      localStorage.setItem(localStorageItems.refreshToken, refreshToken)
    idToken && localStorage.setItem(localStorageItems.idToken, idToken)
  },
  clearAuthTokens: () => {
    localStorage.removeItem(localStorageItems.refreshToken)
    localStorage.removeItem(localStorageItems.idToken)
  },

  // FLAGS
  setLocalStorageFlag: (flag: string) => localStorage.setItem(flag, "true"),
  setNewUserLocalStorageFlags: function (this: LocalStorageInterface) {
    this.setLocalStorageFlag(localStorageItems.onboardStep1)
    this.setLocalStorageFlag(localStorageItems.onboardStep2)
    this.setLocalStorageFlag(localStorageItems.onboardStep3)
    this.setLocalStorageFlag(localStorageItems.showDownloadAppBanner)
    this.setLocalStorageFlag(localStorageItems.needsToSeePaywall)
    this.setLocalStorageFlag(localStorageItems.needsToFireRegistrationEvent)
    this.setLocalStorageFlag(localStorageItems.needsToSeeVerifyFreemiumPopup)
    this.setLocalStorageFlag(
      localStorageItems.needsToShowChildOnboardAfterBanner
    )
  },

  needsToSeeVerifyFreemiumPopup: () =>
    !!localStorage.getItem(localStorageItems.needsToSeeVerifyFreemiumPopup),
  clearNeedsToSeeVerifyFreemiumPopup: () =>
    localStorage.removeItem(localStorageItems.needsToSeeVerifyFreemiumPopup),
}

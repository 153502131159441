import {
  Button,
  SPACING,
  FlexBox,
  JoonUIColor,
  Shadow,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import {
  AnswerInterface,
  QuestionAnswerMappingInterface,
  QuestionInterface,
} from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import BackButton from "../../buttons/BackButton"
import LinearProgress from "../../linearProgress/LinearProgress"
import PageLoader from "../../loading/PageLoader"
import QuestionnaireCheckbox from "../../questionnaireCheckbox/questionnaireCheckbox"

interface AnswerButtonProps {
  didSelectAnswer: (answer: AnswerInterface) => void
  answer: AnswerInterface
}

function AnswerButton({ didSelectAnswer, answer }: AnswerButtonProps) {
  const [isSelected, setIsSelected] = useState(false)

  const selectAnswer = () => {
    if (isSelected) return

    setIsSelected(true)
    setTimeout(function () {
      setIsSelected(false)
      didSelectAnswer(answer)
    }, 250)
  }

  const buttonStyles = isSelected
    ? {
        background: JoonUIColor.background.accent,
        color: JoonUIColor.text.inverted,
      }
    : {
        background: JoonUIColor.background.primaryNeutral,
        color: JoonUIColor.text.primary,
      }

  return (
    <button
      style={{
        borderRadius: SPACING.space6,
        padding: SPACING.space4,
        boxShadow: Shadow.high,
        fontWeight: "600",
        width: "100%",
        height: "fit-content !important",
        minHeight: "50px",
        fontSize: "16px",
        textAlign: "left",
        ...buttonStyles,
      }}
      onClick={selectAnswer}
    >
      {answer.answer}
    </button>
  )
}

interface QuestionnaireSectionProps {
  questions: QuestionInterface[] | []
  onConfirm: ({ questionAnswerMapping }: any) => void
  previousStep: () => void
  lastSeenStep?: number
  fixedProgressBar?: boolean
}

function QuestionnaireSection({
  questions,
  onConfirm,
  previousStep,
  lastSeenStep,
  fixedProgressBar = true,
}: QuestionnaireSectionProps) {
  const [questionAnswerMapping, setQuestionAnswerMapping] =
    useState<QuestionAnswerMappingInterface>({})
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    lastSeenStep || 0
  )

  const goToPreviousStep = () => {
    if (currentQuestionIndex === 0) previousStep()
    else setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  const didSelectAnswer = (answer: AnswerInterface) => {
    const currentQuestion = questions[currentQuestionIndex]
    const newMapping = {
      ...questionAnswerMapping,
      [currentQuestion.id]: [answer.id],
    }
    trackAnalyticEvent(
      ANALYTIC_EVENTS.ONBOARDING_QUESTION(currentQuestion.id),
      { answers: [answer.id] }
    )
    setQuestionAnswerMapping(newMapping)
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }

  const toggleMultiSelectAnswer = (answer: AnswerInterface) => {
    const currentQuestion = questions[currentQuestionIndex]
    const currentAnswers = questionAnswerMapping[currentQuestion.id] || []
    const newAnswers = currentAnswers.includes(answer.id)
      ? currentAnswers.filter((id) => id !== answer.id)
      : [...currentAnswers, answer.id]
    const newMapping = {
      ...questionAnswerMapping,
      [currentQuestion.id]: newAnswers,
    }
    trackAnalyticEvent(
      ANALYTIC_EVENTS.ONBOARDING_QUESTION(currentQuestion.id),
      { answers: newAnswers }
    )
    setQuestionAnswerMapping(newMapping)
  }

  useEffect(() => {
    if (questions.length > 0 && currentQuestionIndex >= questions.length) {
      Object.entries(questionAnswerMapping).forEach(([questionId, answerIds]) =>
        trackAnalyticEvent(
          ANALYTIC_EVENTS.ONBOARDING_QUESTION(Number(questionId)),
          { answers: answerIds }
        )
      )

      onConfirm({ questionAnswerMapping })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex])

  if (currentQuestionIndex >= questions.length) return <PageLoader />

  const currentQuestion = questions[currentQuestionIndex]

  return (
    <div className="questionnaire-section">
      <BackButton onBack={goToPreviousStep} />
      <div className="questionnaire-content">
        <div className="questionnaire-question">{currentQuestion.question}</div>
        {currentQuestion.type === "single_choice" ? (
          <div className="questionnaire-answers">
            {currentQuestion.answers.map((answer) => (
              <AnswerButton answer={answer} didSelectAnswer={didSelectAnswer} />
            ))}
          </div>
        ) : (
          <FlexBox direction="column" gap={SPACING.space4}>
            <div className="questionnaire-answers">
              {currentQuestion.answers.map((answer, index) => (
                <QuestionnaireCheckbox
                  name={answer.answer}
                  selected={questionAnswerMapping[currentQuestion.id]?.includes(
                    answer.id
                  )}
                  onChange={() => toggleMultiSelectAnswer(answer)}
                  key={index}
                  label={answer.answer}
                />
              ))}
            </div>
            <Button
              text="Next"
              fullWidth
              disabled={
                !questionAnswerMapping[currentQuestion.id] ||
                questionAnswerMapping[currentQuestion.id].length === 0
              }
              onClick={() => {
                setCurrentQuestionIndex(currentQuestionIndex + 1)
              }}
            />
          </FlexBox>
        )}
      </div>
      <p />
      <div className={`progress-bar ${fixedProgressBar ? "fixed-bottom" : ""}`}>
        <LinearProgress
          value={((currentQuestionIndex + 1) / questions.length) * 100}
        />
      </div>
    </div>
  )
}

export default QuestionnaireSection

import AcademyPageHeader from "./AcademyPageHeader"
import TrainingsList from "./TrainingsList"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import { requireAuth } from "../../util/auth"

const AcademyPage = requireAuth(() => {
  return (
    <PageWrapper
      style={{
        background: GRADIENTS.gradient100,
        paddingBottom: "200px",
      }}
    >
      <AcademyPageHeader />
      <TrainingsList />
    </PageWrapper>
  )
})

const AcademyPageWithAuth = requireAuth(AcademyPage)
export default AcademyPageWithAuth

import {
  Button,
  DateInput,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextInput,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useState } from "react"

import QuestionnaireDisclaimer from "./questionnaires/QuestionnaireDisclaimer"
import QuestionnaireSection from "./questionnaires/QuestionnaireSection"
import useOnboardContext, { ChildStep } from "../../hooks/useOnboardContext"
import DotersGroupedWelcomeImage from "../../images/doter-group-welcome-screen.png"
import { useQuestionnairesQuery } from "../../networking/queries"
import { ChildDataInterface } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"
import BackButton from "../buttons/BackButton"
import InputSectionBox from "../inputSectionBox/InputSectionBox"
import UserComponent from "../UserComponent"

interface AddChildInformationProps {
  name?: string
  birthday?: dayjs.Dayjs | null
  onRemoveRow: () => void
  onDoneAdding: (name: string, birthday: dayjs.Dayjs | null) => void
  setEditingChildIndex: (index: number | null) => void
}

function AddChildInformation({
  name: initialName,
  birthday: initialBirthday,
  onRemoveRow,
  onDoneAdding,
  setEditingChildIndex,
}: AddChildInformationProps) {
  const [name, setName] = useState(initialName ?? "")
  const [birthday, setBirthday] = useState(initialBirthday ?? null)
  const [warningText, setWarningText] = useState("")

  const onClickDoneAdding = () => {
    if (name == null || name.length === 0) {
      setWarningText("Please enter a name")
      return
    }
    trackAnalyticEvent(ANALYTIC_EVENTS.INPUT_CHILD_INFORMATION)
    onDoneAdding(name, birthday)
  }

  return (
    <InputSectionBox
      style={{
        marginTop: SPACING.space3,
        padding: SPACING.space4,
        background: "white",
        borderRadius: SPACING.space2,
        boxShadow: "4px 6px 6px rgba(0, 0, 0, 0.04)",
      }}
    >
      <FlexBox direction="column" gap={SPACING.space4}>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodyBold">Child's Name</Typography>
          <TextInput
            placeholder="First name"
            onChange={(event: any) => setName(event.target.value)}
            value={name}
            name="firstName"
            fullWidth
          />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodyBold">Child's Birthday</Typography>
          <DateInput
            name="birthday"
            modalTitle="Child's Birthday"
            date={birthday?.toDate() || null}
            setDate={(date: Date) => setBirthday(dayjs(date))}
          />
          <Typography variant="bodyXSmall">
            (Optional) We ask for their birthday so we can suggest chores and
            habits based on their age.
          </Typography>
        </FlexBox>
        <FlexBox justify="center">
          {warningText.length > 0 && (
            <Typography variant="caption" color={JoonUIColor.semantic.alert}>
              {warningText}
            </Typography>
          )}
        </FlexBox>
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          justify="space-between"
          gap={SPACING.space4}
        >
          {!!initialName && (
            <Button
              text="Remove"
              buttonType="redPrimary"
              onClick={onRemoveRow}
            />
          )}
          <FlexBox
            gap={SPACING.space2}
            wrap={false}
            align="center"
            justify="flex-end"
          >
            <button onClick={() => setEditingChildIndex(null)}>
              <Typography variant="bodyBold" color={JoonUIColor.text.secondary}>
                Cancel
              </Typography>
            </button>
            <Button
              text="Continue"
              onClick={onClickDoneAdding}
              style={{ minWidth: "40%" }}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </InputSectionBox>
  )
}

function OnboardAddChildrenSection() {
  const {
    previousStep,
    setCreatingPlan,
    nextStep,
    setParentData,
    parentData,
    childStep,
    setChildStep,
  } = useOnboardContext()

  const [childrenData, setChildrenData] = useState(parentData.childrenData)
  const [editingChildIndex, setEditingChildIndex] = useState<number | null>(
    null
  )
  const [tempChildInfo, setTempChildInfo] = useState<ChildDataInterface>(
    {} as ChildDataInterface
  )
  const { data: questionsResponse } = useQuestionnairesQuery()
  if (!questionsResponse) return null
  const { childQuestions: questions } = questionsResponse

  const onDoneAdding = (name: string, birthday: dayjs.Dayjs | null) => {
    setTempChildInfo({
      first_name: name,
      birthdate: birthday && birthday.unix(),
      questionAnswerMapping: [],
    })
    setChildStep(ChildStep.QuestionnaireDisclaimer)
  }

  const onRemoveRow = () => {
    if (editingChildIndex == null) return
    if (editingChildIndex >= 0) {
      const newChildrenData = [...childrenData]
      newChildrenData.splice(editingChildIndex, 1)
      setChildrenData(newChildrenData)
    }
    setEditingChildIndex(null)
  }

  const finishQuestionnaire = (data: ChildDataInterface) => {
    if (editingChildIndex == null) return

    // If user said their child has ABA therapist, we want to show the ABA invite section
    const childHasABAAnswer = 632
    if (
      Object.values(data.questionAnswerMapping).some((answerIds) =>
        answerIds.includes(childHasABAAnswer)
      )
    ) {
      localStorage.setItem(localStorageItems.showABAInviteSection, "true")
      trackAnalyticEvent(ANALYTIC_EVENTS.ABA_QUESTION_ANSWERED)
    }

    const newChild = {
      first_name: tempChildInfo.first_name,
      birthdate: tempChildInfo.birthdate,
      questionAnswerMapping: data.questionAnswerMapping,
    }
    if (editingChildIndex < 0) {
      const newChildrenData = [...childrenData, newChild]
      setChildrenData(newChildrenData)
    } else {
      const newChildrenData = [...childrenData]
      newChildrenData[editingChildIndex] = newChild
      setChildrenData(newChildrenData)
    }
    setEditingChildIndex(null)
    setChildStep(ChildStep.ChildInfo)
  }

  if (childStep === ChildStep.ChildInfo) {
    return (
      <>
        <BackButton onBack={previousStep} />
        <FlexBox direction="column" align="center">
          <img
            src={DotersGroupedWelcomeImage}
            alt="Doters"
            style={{ width: "50%" }}
          />
          <FlexBox justify="center" align="center">
            <Typography variant="h2" style={{ margin: "16px 0" }}>
              {childrenData.length === 0
                ? "Add your first child"
                : "Add Your Children"}
            </Typography>
          </FlexBox>
          <FlexBox gap={SPACING.space2} wrap={false} direction="column">
            {childrenData.map((childData, index) => {
              const isEditingChild = index === editingChildIndex
              return (
                <FlexBox>
                  {isEditingChild ? (
                    <AddChildInformation
                      name={childData.first_name}
                      birthday={
                        childData.birthdate
                          ? dayjs.unix(childData.birthdate)
                          : null
                      }
                      onDoneAdding={onDoneAdding}
                      onRemoveRow={onRemoveRow}
                      setEditingChildIndex={setEditingChildIndex}
                    />
                  ) : (
                    <UserComponent
                      name={childData.first_name}
                      index={index}
                      type={UserRole.CHILD}
                      editChild={() => setEditingChildIndex(index)}
                    />
                  )}
                </FlexBox>
              )
            })}
          </FlexBox>

          {editingChildIndex == null && (
            <Button
              buttonType="secondary"
              text="+ Add Child"
              onClick={() => {
                setTimeout(
                  () => window.scrollTo(0, document.body.scrollHeight),
                  100
                )
                trackAnalyticEvent(ANALYTIC_EVENTS.INPUT_CHILD_INFO_INTENT)
                setEditingChildIndex(-1)
              }}
              style={{ width: "150px", marginTop: SPACING.space4 }}
            />
          )}
          {editingChildIndex === -1 && (
            <AddChildInformation
              onDoneAdding={onDoneAdding}
              onRemoveRow={onRemoveRow}
              setEditingChildIndex={setEditingChildIndex}
            />
          )}
          {childrenData.length > 0 && editingChildIndex === null && (
            <Button
              fullWidth
              style={{ marginTop: "24px" }}
              onClick={() => {
                setCreatingPlan(true)
                nextStep()
                trackAnalyticEvent(ANALYTIC_EVENTS.ADD_CHILDREN)
                setParentData("childrenData", childrenData)
              }}
              text="Next"
            />
          )}
        </FlexBox>
      </>
    )
  } else if (childStep === ChildStep.QuestionnaireDisclaimer) {
    return (
      <>
        <BackButton onBack={() => setChildStep(ChildStep.ChildInfo)} />
        <QuestionnaireDisclaimer
          childName={tempChildInfo?.first_name}
          onConfirm={() => setChildStep(ChildStep.Questionnaire)}
        />
      </>
    )
  } else if (childStep === ChildStep.Questionnaire) {
    return (
      <QuestionnaireSection
        questions={questions}
        onConfirm={finishQuestionnaire}
        previousStep={() => setChildStep(ChildStep.ChildInfo)}
      />
    )
  } else return <div></div>
}

export default OnboardAddChildrenSection

import { ChevronRightIcon, JoonUIColor } from "@joonapp/web-shared"

import useIncidentReportStore from "./useIncidentReportStore"
import Card from "../../../components/card/Card"
import { useIncidentTemplatesQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const ReportIncidentButton = () => {
  const { onOpen } = useIncidentReportStore()
  const { data: incidentTemplates, isLoading } = useIncidentTemplatesQuery()

  if (isLoading || incidentTemplates?.length === 0) return <></>

  return (
    <Card
      title="Report Behavior Incident"
      onClick={(e) => {
        e.stopPropagation()
        onOpen()
        trackAnalyticEvent(ANALYTIC_EVENTS.INITIATE_LOG_INCIDENT)
      }}
      buttonComponent={
        <ChevronRightIcon size={24} color={JoonUIColor.icon.neutral} />
      }
    />
  )
}

export default ReportIncidentButton

import { DropdownOption } from "@joonapp/web-shared"

import useQuestBoardStore from "./useQuestBoardStore"
import ToggleHeader from "../../components/toggleHeader/ToggleHeader"
import { useFamilyQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { getChildImage } from "../../util/util"

const QuestBoardChildToggleHeader = () => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { selectedChildId, setSelectedChildId } = useQuestBoardStore()

  // If there is only one child, don't show the "All kids" option
  let childOptions: DropdownOption[] =
    children?.map((child) => ({
      label: child.user.name,
      value: child.user.id,
      imageUrl: getChildImage(child.user),
    })) || []
  if (childOptions.length > 1) {
    childOptions.unshift({
      label: "All Kids",
      value: null,
      imageUrl: null,
    })
  }

  const goToNextChild = () => {
    const currentIndex = childOptions.findIndex(
      (option) => option.value === selectedChildId
    )
    const nextIndex = (currentIndex + 1) % childOptions.length
    setSelectedChildId(childOptions[nextIndex].value as number | null)
  }

  const goToPreviousChild = () => {
    const currentIndex = childOptions.findIndex(
      (option) => option.value === selectedChildId
    )
    const previousIndex =
      (currentIndex - 1 + childOptions.length) % childOptions.length
    setSelectedChildId(childOptions[previousIndex].value as number | null)
  }

  return (
    <ToggleHeader
      options={childOptions}
      selectedOption={
        childOptions.find((option) => option.value === selectedChildId) || {
          label: "Loading...",
          value: null,
          imageUrl: null,
        }
      }
      onClickNext={goToNextChild}
      onClickPrevious={goToPreviousChild}
    />
  )
}

export default QuestBoardChildToggleHeader

import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { useAddPhoneNumberModalStore } from "./useAddPhoneNumberModalStore"
import { useNotificationPreferencesMutation } from "./useNotificationPreferencesMutation"
import { usePhoneNumberMutation } from "./usePhoneNumberMutation"
import {
  useNotificationPreferencesQuery,
  useTherapistsQuery,
  useUserQuery,
} from "../../../networking/queries"
import { SetPhoneNumberSource } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"

const CountryCode = () => {
  return (
    <>
      <Typography variant="body" style={{ paddingRight: SPACING.space4 }}>
        +1
      </Typography>
      <div
        style={{
          borderLeft: `1px solid ${JoonUIColor.border.default}`,
          height: SPACING.space10,
        }}
      ></div>
    </>
  )
}

const AddPhoneNumberModal = () => {
  const router = useRouter()
  const { data: notificationPreferences } = useNotificationPreferencesQuery()
  const initialChecked = !!notificationPreferences?.receive_sms
  const { user } = useUserQuery()
  const initialPhoneNumber = user?.phone_number || ""
  const onSettings = router.pathname.includes("/settings")
  const { data: therapist } = useTherapistsQuery()
  const therapistName =
    therapist && therapist.length > 0 ? therapist[0].nickname : "your therapist"
  const {
    isOpen,
    onClose,
    phoneNumber,
    setPhoneNumber,
    isChecked,
    setIsChecked,
  } = useAddPhoneNumberModalStore()

  const notificationPreferencesMutation = useNotificationPreferencesMutation()
  const phoneNumberMutation = usePhoneNumberMutation()

  const [phoneNumberInput, setPhoneNumberInput] = useState("")

  const isValidPhoneNumber = () => {
    const phoneRegex = /^\+1\d{10}$/
    return phoneRegex.test(phoneNumber)
  }

  const onCheck = () => {
    setIsChecked(!isChecked)
  }

  const onChangePhoneNumber = (value: string) => {
    const cleanedValue = value.replace(/\D/g, "")
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)
    if (!match) return
    let formattedValue = match[1] ? `(${match[1]}` : ""
    if (match[2]) formattedValue += `) ${match[2]}`
    if (match[3]) formattedValue += `-${match[3]}`
    setPhoneNumber("+1" + cleanedValue)
    setPhoneNumberInput(formattedValue)
  }

  const onAddNumber = async () => {
    Promise.all([
      phoneNumberMutation.mutateAsync(phoneNumber),
      notificationPreferencesMutation.mutateAsync(true),
    ]).then(() => {
      trackAnalyticEvent(ANALYTIC_EVENTS.SET_PHONE_NUMBER, {
        source: SetPhoneNumberSource.POST_THERAPIST_LINK,
      })
      if (router.pathname.includes("/paywall")) {
        router.push("/me")
      }
      onClose()
    })
  }

  const onSkip = () => {
    if (router.pathname.includes("/paywall")) {
      router.push("/me")
    }
    onClose()
  }

  const onSave = async () => {
    const promises = []
    if (phoneNumber !== initialPhoneNumber) {
      promises.push(phoneNumberMutation.mutateAsync(phoneNumber))
    }
    if (isChecked !== initialChecked) {
      promises.push(notificationPreferencesMutation.mutateAsync(isChecked))
    }
    Promise.all(promises).then(() => {
      trackAnalyticEvent(ANALYTIC_EVENTS.SET_PHONE_NUMBER, {
        source: SetPhoneNumberSource.SETTINGS,
      })
      onClose()
    })
  }

  useEffect(() => {
    if (!isOpen) return
    setIsChecked(initialChecked)
    onChangePhoneNumber(initialPhoneNumber.substring(2))
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isOpen}
      onClose={onSkip}
      displayCloseIcon={false}
      animate
      style={{ width: "min(500px, 95vw)" }}
    >
      <ModalHeader
        title={
          onSettings ? "Text message notification settings" : "Add phone number"
        }
        onClose={onSkip}
        style={{
          fontSize: "20px",
          width: "min(500px, 95vw)",
          paddingTop: SPACING.space3,
          paddingLeft: SPACING.space6,
          paddingRight: SPACING.space4,
          paddingBottom: SPACING.space0,
        }}
      />
      {!onSettings && (
        <hr
          color={JoonUIColor.border.default}
          style={{ marginTop: SPACING.space3 }}
        />
      )}
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{
          width: "min(500px, 95vw)",
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <Typography
          variant={onSettings ? "body" : "bodySmall"}
          style={{ textWrap: "wrap", lineHeight: 1.25 }}
        >
          {onSettings
            ? "Phone number"
            : `This will allow ${therapistName} to send you notifications, reminders, or helpful tips on how to best help your child at home.`}
        </Typography>
        <TextInput
          name="Phone Number"
          value={phoneNumberInput}
          onChange={(e) => onChangePhoneNumber(e.target.value)}
          maxLength={14}
          placeholder="(123) 456-7890"
          fullWidth
          Icon={CountryCode}
          style={{
            height: SPACING.space14,
            background: JoonUIColor.background.xlightGray,
            paddingLeft: SPACING.space5,
          }}
        />
        {onSettings && (
          <FlexBox direction="column" align="left">
            <Checkbox
              name="Toggle notifications"
              label={
                <Typography
                  variant="body"
                  style={{ marginLeft: SPACING.space1 }}
                >
                  Enable text message notifications
                </Typography>
              }
              onChange={onCheck}
              selected={!!isChecked}
              hideBorder
            />
            <Button
              text="Save Settings"
              buttonType="secondary"
              onClick={onSave}
              disabled={!isValidPhoneNumber()}
              fullWidth
              isLoading={
                notificationPreferencesMutation.isLoading ||
                phoneNumberMutation.isLoading
              }
              style={{
                marginTop: SPACING.space4,
                marginBottom: SPACING.space4,
              }}
            />
          </FlexBox>
        )}

        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.secondary}
          style={{
            borderRadius: SPACING.space2,
            padding: SPACING.space4,
            background: JoonUIColor.background.xlightGray,
            lineHeight: 1.25,
          }}
        >
          <strong>Note: </strong>
          We send a daily text message reminder for things your clinician has
          shared with you to help keep you on track with your goals. Your number
          will not be shared with any third parties. Reply STOP any time to
          cancel, or HELP for more info. Only US numbers are currently
          supported.
        </Typography>

        {!onSettings && (
          <FlexBox justify="flex-end" gap={SPACING.space4}>
            <TextButton onClick={onSkip}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Skip for now
              </Typography>
            </TextButton>
            <Button
              text="Add number"
              buttonType="primary"
              onClick={onAddNumber}
              disabled={!isValidPhoneNumber()}
              isLoading={
                notificationPreferencesMutation.isLoading ||
                phoneNumberMutation.isLoading
              }
            />
          </FlexBox>
        )}
      </FlexBox>
    </Modal>
  )
}

export default AddPhoneNumberModal

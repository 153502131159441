import {
  useIncidentTemplatesQuery,
  useParentTaskQuery,
} from "../../../networking/queries"

export const useDisplayParentTaskSection = () => {
  const { data: parentTasks } = useParentTaskQuery()
  const { data: incidentTemplates } = useIncidentTemplatesQuery()

  const hasIncidentLogTemplates =
    incidentTemplates && incidentTemplates.length > 0

  const displayParentTasksSection =
    hasIncidentLogTemplates || (parentTasks && parentTasks.length > 0)

  return { displayParentTasksSection }
}

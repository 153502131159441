import * as Sentry from "@sentry/react"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useEffect } from "react"
import { create } from "zustand"

import { QUERY_KEYS } from "../constants"
import {
  createChildren,
  createFamily,
  createUser,
} from "../networking/authentication"
import {
  useFamilyQuery,
  useQuestionnairesQuery,
  useSubscriberInfo,
  useUserNetworkInfo,
  useUserQuery,
} from "../networking/queries"
import {
  getSubscriberInfo,
  joinFamily,
  loadFamilies,
  loadUser,
} from "../networking/user"
import { ParentDataInterface } from "../types"
import { GAEvent, trackAnalyticEvent, trackGAEvent } from "../util/analytics"
import {
  submitChildQuestionnaires,
  submitParentQuestionnaires,
} from "../util/questionnaires"
import { history } from "../util/router"
import { localStorageItems, sessionManager } from "../util/storage"
import { apiRequest, separateFamily } from "../util/util"

export const OnboardingStep = {
  ParentingQuestionnaire: 0,
  AddChildOrJoinFamily: 1,
  CoparentCode: 2,
  AddChildren: 3,
  ParentNameNickname: 4,
  ParentAvatar: 5,
  EmailPassword: 6,
  CreateAccountWithCredentials: 7,
}

export const ChildStep = Object.freeze({
  ChildInfo: 0,
  QuestionnaireDisclaimer: 1,
  Questionnaire: 2,
})

interface OnboardContextStore {
  isCoparent: boolean
  setIsCoparent: (value: boolean) => void
  coparentCode: string
  setCoparentCode: (value: string) => void

  parentData: ParentDataInterface
  creatingPlan: boolean
  setCreatingPlan: (value: boolean) => void
  currentStep: number
  childStep: number
  parentQuestionnaireStep: number
  setParentQuestionnaireStep: (value: number) => void
  setChildStep: (value: number) => void
  setParentData: (
    fieldName:
      | "name"
      | "nickname"
      | "avatar"
      | "parentQuestionnaireMapping"
      | "childrenData",
    value: any
  ) => void
  setStep: (value: number) => void
  nextStep: () => void
  previousStep: () => void
  isSubmittingData: boolean
  setIsSubmittingData: (value: boolean) => void
}

const useOnboardContext = create<OnboardContextStore>((set) => ({
  isCoparent: false,
  setIsCoparent: (value: boolean) => set({ isCoparent: value }),
  coparentCode: "",
  setCoparentCode: (value: string) =>
    set({ coparentCode: value.toUpperCase() }),

  parentData: {
    name: "",
    nickname: "",
    avatar: null,
    parentQuestionnaireMapping: {},
    childrenData: [],
  },
  setParentData: (fieldName, value) =>
    set((state) => ({
      parentData: { ...state.parentData, [fieldName]: value },
    })),

  creatingPlan: false,
  setCreatingPlan: (value: boolean) => set({ creatingPlan: value }),
  // STEPS
  currentStep: 0,
  childStep: 0,
  parentQuestionnaireStep: 0,
  setParentQuestionnaireStep: (value: number) =>
    set({ parentQuestionnaireStep: value }),

  setChildStep: (value: number) => set({ childStep: value }),
  setStep: (value: number) => set({ currentStep: value }),
  nextStep: () => {
    set((state: OnboardContextStore) => {
      if (state.currentStep === OnboardingStep.CoparentCode) {
        return { currentStep: OnboardingStep.ParentNameNickname }
      } else if (
        state.currentStep === OnboardingStep.ParentAvatar &&
        sessionManager.hasRefreshToken()
      ) {
        return { currentStep: OnboardingStep.CreateAccountWithCredentials }
      } else return { currentStep: state.currentStep + 1 }
    })
  },
  previousStep: () => {
    set((state: OnboardContextStore) => {
      if (state.currentStep === 0) {
        sessionManager.clearAuthTokens()
        history.goBack()
        return {}
      } else if (state.currentStep === OnboardingStep.ParentNameNickname) {
        return state.isCoparent
          ? { currentStep: OnboardingStep.CoparentCode }
          : { currentStep: OnboardingStep.AddChildren }
      } else if (state.currentStep === OnboardingStep.AddChildren) {
        return { currentStep: OnboardingStep.AddChildOrJoinFamily }
      } else return { currentStep: state.currentStep - 1 }
    })
  },

  // Data submit
  isSubmittingData: false,
  setIsSubmittingData: (value: boolean) => set({ isSubmittingData: value }),
}))

export const useAccountCreation = () => {
  const {
    isSubmittingData,
    setIsSubmittingData,
    parentData,
    isCoparent,
    coparentCode,
  } = useOnboardContext()
  const queryClient = useQueryClient()

  const { data: questionsResponse, status } = useQuestionnairesQuery()

  const uploadOnboardingSecondaryData = async () => {
    if (status === "loading" || status === "error") return {}
    const { onboardingQuestionnaires } = questionsResponse
    if (isSubmittingData) return

    setIsSubmittingData(true)
    try {
      // Create User, Family, and Children
      await createUser(parentData.name, parentData.avatar as string)
      trackAnalyticEvent("create-acc-user-created")

      if (isCoparent) {
        await joinFamily(coparentCode, parentData.nickname)
        trackAnalyticEvent("create-acc-family-joined")
      } else {
        const family = await createFamily(parentData.nickname)
        await createChildren(family.data.id, parentData.childrenData)
        trackAnalyticEvent("create-acc-family-created")

        // Set marker to show freemium popup when verifying first Quest.
        // We only need to set new user flags for those that are creating a family.
        sessionManager.setNewUserLocalStorageFlags()
      }

      const familiesData = await loadFamilies()

      // Set paywall flag if applicable
      const subscriberInfo = await getSubscriberInfo(familiesData)
      if (!subscriberInfo.planIsActive) {
        sessionManager.setLocalStorageFlag(localStorageItems.needsToSeePaywall)
      }

      // Submit Questionnaires
      const userData = await loadUser()
      if (!familiesData || !userData)
        throw new Error(
          `Error loading data families:${familiesData} user:${userData}`
        )
      const { children } = separateFamily(userData, familiesData)
      submitChildQuestionnaires(onboardingQuestionnaires, parentData, children)
      submitParentQuestionnaires(
        onboardingQuestionnaires,
        parentData,
        userData.id
      )
      trackAnalyticEvent("create-acc-questionnaires-submitted")

      trackGAEvent(GAEvent.CREATE_ACCOUNT)
    } catch (error) {
      Sentry.withScope((scope) => {
        if (error instanceof AxiosError) {
          scope.setExtra("response", error.response)
        }
        Sentry.captureException(error)
      })
      throw new Error("Error creating account")
    } finally {
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      setIsSubmittingData(false)
    }
  }
  return { uploadOnboardingSecondaryData }
}

export default useOnboardContext

export const usePaywall = () => {
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { data: subscriber } = useSubscriberInfo()
  const { data: networkInfo } = useUserNetworkInfo()
  const needsToSeePaywall = localStorage.getItem(
    localStorageItems.needsToSeePaywall
  )
  const redirectLink = localStorage.getItem(localStorageItems.redirectLink)

  useEffect(() => {
    if (!user || !family) return

    // Update RevenueCat user properties.
    const subscriber_id = family.subscriber_id
    const parent_id = user.legacy_parent_id ?? user.id

    const ip_address = encodeURIComponent(networkInfo?.ip)

    apiRequest(
      `update-subscriber-info?subscriber_id=${subscriber_id}&parent_id=${parent_id}&name=${user.name}&email=${user.email}&ip_address=${ip_address}`
    )

    // eventually we want to instead have everyone sent to the paywall
    // and the paywall is dynamic with the redirect link params
    // For now, if they have redirect, they bypass paywall
    if (!!redirectLink) {
      history.push(redirectLink)
      localStorage.removeItem(localStorageItems.redirectLink)
      localStorage.removeItem(localStorageItems.needsToSeePaywall)
    } else if (!!needsToSeePaywall) {
      // If a user is not a coparent, or if they are a coparent and not subscribed, show them paywall
      const userIsCoparent = localStorage.getItem(localStorageItems.isCoparent)
      const familyIsSubscribed = subscriber?.activePlan.isActive

      if (!userIsCoparent || !familyIsSubscribed) {
        history.push("/paywall/trial")
        localStorage.removeItem(localStorageItems.needsToSeePaywall)
        localStorage.removeItem(localStorageItems.isCoparent)
      }
    }
  }, [user, family, needsToSeePaywall, redirectLink, subscriber, networkInfo])
}

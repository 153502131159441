import {
  Button,
  CloseIcon,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { isChrome, isMobile, isAndroid } from "react-device-detect"

import PaywallReferralModal, {
  usePaywallReferralModalStore,
} from "./PaywallReferralModal"
import { useCheckoutRedirect } from "./useCheckoutRedirect"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import ParentSignupReasons from "../../components/onboarding/ParentSignupReasons"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import useMediaQuery from "../../hooks/useMediaQuery"
import { useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

const Paywall = () => {
  useDocumentTitle("Joon - Start Trial")
  const [isAtTop, setIsAtTop] = useState(true)

  const isSmallScreen = useMediaQuery("(max-height:750px)")
  const router = useRouter()
  const { user } = useUserQuery()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openPaywallReferralModal, hasValidCode } =
    usePaywallReferralModalStore()
  const { isLoadingCheckoutFn, isRedirecting, setIsRedirecting } =
    useCheckoutRedirect()

  const dateIn7days = dayjs().add(7, "day").format("MMMM D")
  const isOnMobileChrome = isChrome && isMobile && isAndroid

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_PAYWALL)
    const handleScroll = () => {
      if (window.scrollY === 0) setIsAtTop(true)
      else setIsAtTop(false)
    }
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const scrollToBottom = () =>
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    })

  if (!user) return <></>

  return (
    <>
      <div className="paywall-page">
        <img
          src={"/images/logos/logo_white.svg"}
          alt="Joon"
          className="joon-logo"
        />
        <button
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.PAYWALL_SKIP)
            router.push("/me")
            if (isOnMobileChrome) openAddToHomescreenModal()
            else openIOSModal()
          }}
          className="close-button"
        >
          <CloseIcon color={JoonUIColor.text.disabled} size={24} />
        </button>
        <div className="paywall-title my-12">{user?.name}'s Custom Plan</div>
        <ParentSignupReasons />
        <div className="paywall-ft-title">How your free trial works</div>
        <div className="paywall-content">
          <div style={{ position: "relative" }}>
            <div className="paywall-timeline">
              <div className="paywall-timeline-bg" />
              <div className="paywall-section">
                <img
                  src="/images/icons/unlock.svg"
                  className="paywall-icon"
                  alt="bell"
                />
              </div>
              <div className="paywall-section">
                <img
                  src="/images/icons/bell.svg"
                  className="paywall-icon"
                  alt="bell"
                />
              </div>
              <div style={{ height: "40px", paddingTop: "2px" }}>
                <img
                  src="/images/icons/star.svg"
                  className="paywall-icon"
                  alt="bell"
                />
              </div>
            </div>
            <div className="paywall-timeline-extension"></div>
          </div>
          <div className="paywall-steps">
            <div className="paywall-section">
              <div className="paywall-step-title">Today</div>
              <div className="paywall-step-subtitle">
                Start your full access to your tailored program for your family.
              </div>
            </div>
            <div className="paywall-section">
              <div className="paywall-step-title">In 5 days</div>
              <div className="paywall-step-subtitle">
                Get a reminder about when your trial will end.
              </div>
            </div>
            <div className="paywall-section">
              <div className="paywall-step-title">In 7 days</div>
              <div className="paywall-step-subtitle">
                You'll be charged on {dateIn7days}, cancel anytime before.
              </div>
            </div>
          </div>
        </div>
        <div className="paywall-action-box">
          {isAtTop && isSmallScreen && (
            <button onClick={scrollToBottom} className="scroll-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="12"
                viewBox="0 0 40 14"
              >
                <path
                  d="M0 0 L20 12 L40 0"
                  fill="none"
                  stroke="white"
                  stroke-width="4"
                />
              </svg>
            </button>
          )}
          <div className="paywall-action-box__text">
            INTRODUCTORY ONE-TIME OFFER
          </div>
          <div className="semibold">Unlimited free access for 7 days, then</div>
          <div className="paywall-price">
            <span className="paywall-price__old">$10.99</span>
            {hasValidCode && <span className="paywall-price__old">$7.49</span>}
            <span className="paywall-price__new">
              {hasValidCode
                ? "$5.83/month (save 47%)"
                : "$7.49/month (save 32%)"}
            </span>
          </div>
          <Button
            isLoading={isRedirecting && isLoadingCheckoutFn}
            onClick={() => {
              setIsRedirecting(true)
              trackAnalyticEvent(ANALYTIC_EVENTS.PAYWALL_START_TRIAL)
            }}
            style={{
              fontSize: "24px",
              background: "#f39f3d",
              borderColor: "#f39f3d",
              width: "100%",
              marginTop: SPACING.space2,
            }}
            text="Start FREE trial"
          >
            Start FREE trial
          </Button>
          <button onClick={openPaywallReferralModal} className="mt-8">
            <Typography
              variant="bodyBold"
              color={JoonUIColor.semantic.info}
              style={{ textDecoration: "underline" }}
            >
              Referred by a therapist or parent?
            </Typography>
          </button>
          <div className="mt-4 mb-8" style={{ fontSize: "12px" }}>
            Offer expires when you close this screen!
          </div>
        </div>
      </div>
      <PaywallReferralModal />
    </>
  )
}

export default Paywall

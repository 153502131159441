import {
  IconProps,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import NotificationDot from "../../pages/family/NotificationDot"
import { Link, useLocation } from "../../util/router"

type Props = {
  to: string
  name: string
  Icon: React.FC<IconProps>
}

const MobileNavLink = ({ to, name, Icon }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const location = useLocation()
  const isOnDefaultPage = location.pathname === "/" && to === "/me"
  const isActive =
    "/" + location.pathname.split("/")[1] === to || isOnDefaultPage

  const navLinkIconColor =
    isActive || isHovered
      ? JoonUIColor.semantic.primary
      : JoonUIColor.text.disabled
  const navLinkTextColor =
    isActive || isHovered
      ? JoonUIColor.semantic.primary
      : JoonUIColor.text.secondary

  return (
    <Link
      style={{
        padding: `${SPACING.space4} 0`,
        textAlign: "center",
        textDecoration: "none",
        flex: "1",
        transition: "0.2s ease-in-out",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: SPACING.space05,
      }}
      to={to}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          position: "relative",
          height: SPACING.space6,
          marginBottom: SPACING.space05,
          transition: "transform 0.1s ease",
          transform: `scale(${isActive ? 1.08 : 1})`,
        }}
      >
        <Icon color={navLinkIconColor} size={24} />
        {to === "/family" && <NotificationDot size="7px" />}
      </div>
      <Typography
        variant="bodyXSmall"
        color={navLinkTextColor}
        style={{ fontWeight: isActive ? "700" : "500" }}
      >
        {name}
      </Typography>
    </Link>
  )
}

export default MobileNavLink
